import React, { useEffect } from "react";
import { Navbar } from "./Navbar/Navbar";
import { Footer } from "./Footer/Footer";
import privyr_logo from "../../src/assets/images/privyr-logo-imagewebp.webp";
import { useNavigate } from "react-router-dom";

export const Affiliate = () => {
  const navigation = useNavigate();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="affiliate-div">
              <div>
                <img
                  src={privyr_logo}
                  alt="privyr-image"
                  className="privyr_logo_image"
                />
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  onClick={() => {
                    window.open(
                      "https://www.privyr.com/intro/facebook?tap_a=69171-cb52c3&tap_s=7112800-064001",
                      "_blank"
                    );
                  }}
                  className="btn mt-3 mb-3 btn-primary affiliate-redirect-btn"
                >
                  Click Here !!!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
