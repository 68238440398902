import React from "react";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import { Home } from "./Pages/Home";
import ContactUs from "./Pages/ContactUs";
import CaseStudy from "./Pages/CaseStudy";
import { WorkWithUs } from "./Pages/WorkWithUs";
import { Agency } from "./Pages/Agency";
import { AboutUs } from "./Pages/AboutUs";
import { Academy } from "./Pages/Academy";
import { Advisory } from "./Pages/Advisory";
import { ToursandWorkshops } from "./Pages/ToursandWorkshops";
import { Blogs } from "./Pages/Blogs";
import { TheRecessionisBeneficial } from "./Pages/TheRecessionisBeneficial";
import { SixStepstoBecomeaCrorepati } from "./Pages/SixStepstoBecomeaCrorepati";
import { TheLawofWastedEfforts } from "./Pages/TheLawofWastedEfforts";
import { ColdEmailingSecrets } from "./Pages/ColdEmailingSecrets";
import { TheCompleteDigitalMarketingCourse12CoursesIn1 } from "./Pages/TheCompleteDigitalMarketingCourse12CoursesIn1";
import { SixPillarsofDigitalMarketing } from "./Pages/SixPillarsofDigitalMarketing";
import { HowtoStartaDigitalMarketingAgency } from "./Pages/HowtoStartaDigitalMarketingAgency";
import { DigitalMarketingProjects } from "./Pages/DigitalMarketingProjects";
import { DigitalMarketingCourseAfter10th } from "./Pages/DigitalMarketingCourseAfter10th";
import { MBAMarketingProjectTopics } from "./Pages/MBAMarketingProjectTopics";
import { DigitalMarketingSpecialist } from "./Pages/DigitalMarketingSpecialist";
import { TypesofDigitalMarketing } from "./Pages/TypesofDigitalMarketing";
import { ScopeofDigitalMarketing } from "./Pages/ScopeofDigitalMarketing";
import { DigitalMarketingTrends } from "./Pages/DigitalMarketingTrends";
import { DigitalMarketingCourseAfter12th } from "./Pages/DigitalMarketingCourseAfter12th";
import { DigitalMarketingJobsforFreshers } from "./Pages/DigitalMarketingJobsforFreshers";
import { PrivacyPolicy } from "./Components/privacypolicy";
import { TermsandConditions } from "./Components/terms";
import { RefundPolicy } from "./Components/refundpolicy";
import { Affiliate } from "./Components/Affiliate";
export const Main = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/CaseStudies" element={<CaseStudy />} />
        <Route path="/WorkWithUs" element={<WorkWithUs />} />
        <Route path="/Advisory" element={<Advisory />} />
        <Route path="/Agency" element={<Agency />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/Academy" element={<Academy />} />
        <Route path="/Blogs" element={<Blogs />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/TermsandConditions" element={<TermsandConditions />} />
        <Route path="/ToursandWorkshops" element={<ToursandWorkshops />} />
        <Route path="/RefundandCancellationPolicy" element={<RefundPolicy />} />


        <Route
          path="/TheRecessionisBeneficial"
          element={<TheRecessionisBeneficial />}
        />
        <Route
          path="/SixStepstoBecomeaCrorepati"
          element={<SixStepstoBecomeaCrorepati />}
        />
        <Route
          path="/TheLawofWastedEfforts"
          element={<TheLawofWastedEfforts />}
        />
        <Route path="/ColdEmailingSecrets" element={<ColdEmailingSecrets />} />
        <Route
          path="/TheCompleteDigitalMarketingCourse12CoursesIn1"
          element={<TheCompleteDigitalMarketingCourse12CoursesIn1 />}
        />
        <Route
          path="/SixPillarsofDigitalMarketing"
          element={<SixPillarsofDigitalMarketing />}
        />
        <Route
          path="/HowtoStartaDigitalMarketingAgency"
          element={<HowtoStartaDigitalMarketingAgency />}
        />
        <Route
          path="/DigitalMarketingProjects"
          element={<DigitalMarketingProjects />}
        />
        <Route
          path="/DigitalMarketingCourseAfter10th"
          element={<DigitalMarketingCourseAfter10th />}
        />
        <Route
          path="/MBAMarketingProjectTopics"
          element={<MBAMarketingProjectTopics />}
        />
        <Route
          path="/DigitalMarketingSpecialist"
          element={<DigitalMarketingSpecialist />}
        />
        <Route
          path="/TypesofDigitalMarketing"
          element={<TypesofDigitalMarketing />}
        />
        <Route
          path="/DigitalMarketingTrends"
          element={<DigitalMarketingTrends />}
        />
        <Route
          path="/ScopeofDigitalMarketing"
          element={<ScopeofDigitalMarketing />}
        />
        <Route
          path="/DigitalMarketingCourseAfter12th"
          element={<DigitalMarketingCourseAfter12th />}
        />
        <Route
          path="/DigitalMarketingJobsforFreshers"
          element={<DigitalMarketingJobsforFreshers />}
        />
        <Route
          path="/Privyr"
          element={<Affiliate />}
        />
      </Routes>
    </HashRouter>
  );
};
